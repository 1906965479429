/* eslint-disable prettier/prettier */
// src/pages/gmb-integration.js

import React from "react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import useFetchLocations from "../hooks/useFetchLocations"; // Import the new hook
import useIframeRedirect from "../hooks/useIframeRedirect"; // Import the redirect hook
import { useAuth0 } from "@auth0/auth0-react";
import Retool from "react-retool"; // Import Retool for the iframe

const GMBIntegrationPage = () => {
  useDocumentTitle("GMB Integration");

  const { user } = useAuth0(); // Get the user object from Auth0
  const { locations, authData, loading, error } = useFetchLocations(); // Get the locations and authData from the hook

  // Initialize the iframe redirect hook with the expected origin
  useIframeRedirect("https://tryrepbot.retool.com");

  // Create the retoolData object with user name, user email, and authData
  const retoolData = {
    userName: user.name,
    userEmail: user.email,
    locations: locations ? locations : [], // Add locations if available
    authData: authData ? authData : {}, // Add authData if available
  };

  // Log the retoolData to check what is being passed
  console.log("Sending data to Retool:", retoolData);

  // If still loading, show a loading message
  if (loading) {
    return <p>Loading business location names...</p>;
  }

  // If there was an error, show an error message
  if (error) {
    return <p>Error loading locations: {error.message}</p>;
  }

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          GMB Integration
        </h1>

        {/* Embed the Retool iframe and pass user name, user email, locations, and authData */}
        <Retool
          url="https://tryrepbot.retool.com/p/gmb-integration" // URL for your Retool app
          width="100%"
          height="800px"
          data={retoolData} // Pass all data to Retool in one object
        />
      </div>
    </PageLayout>
  );
};

export default GMBIntegrationPage;
