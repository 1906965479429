/* eslint-disable prettier/prettier */
// src\components\CustomerReviewDashboard.js
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Retool from "react-retool";
import useIframeModal from "../hooks/useIframeModal"; // Import the modal hook
import useIframeRedirect from "../hooks/useIframeRedirect"; // Import the redirect hook

const CustomerReviewDashboard = () => {
  const { user } = useAuth0();
  const [retoolData, setRetoolData] = useState({}); // State to hold retool data

  // Initialize the iframe modal hook with the expected origin
  useIframeModal("https://tryrepbot.retool.com");

  // Initialize the iframe redirect hook with the expected origin
  useIframeRedirect("https://tryrepbot.retool.com");

  useEffect(() => {
    // Set retool data with userEmail from Auth0 user
    if (user) {
      setRetoolData({
        userEmail: user.email,
      });
    }
  }, [user]);

  if (!user) {
    // If user is not available, show a loading state
    return <p>Loading...</p>;
  }

  return (
    <div>
      <Retool
        url="https://tryrepbot.retool.com/p/response-tone-onboarding"
        width="100%"
        height="900px"
        data={retoolData} // Pass userEmail to Retool
      />
    </div>
  );
};

export default CustomerReviewDashboard;
