/* eslint-disable prettier/prettier */
// src/pages/Post.js
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PageLayout } from "../components/page-layout";

const Post = () => {
  const [alertMessage, setAlertMessage] = useState("");
  const location = useLocation();

  useEffect(() => {
    const checkoutId = new URLSearchParams(location.search).get("c_id");

    if (!checkoutId) {
      window.location.href = "https://repbot.ai";
      return;
    }

    localStorage.setItem("cid", checkoutId);
  }, [location]);

  const copySignupLink = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl);
    setAlertMessage("Signup link copied to clipboard! You can now share it.");
  };

  return (
    <PageLayout>
      <div className="post-container">
        <h1 className="post-heading">Thank You for Subscribing! Your payment was successful.</h1>
        <h2 className="post-subheading">
          You can sign up for an account by clicking the &quot;Sign Up&quot; button at the top right corner of the page.
        </h2>

        <div className="post-box">
          <p className="post-paragraph">
            If you&apos;re not signing up now, click the button below to copy your personalized sign-up link. You can
            email it to yourself or share it with a team member. If this link isn&apos;t used to sign up, you may need to
            contact support to connect the subscription to your account.
          </p>
          <button onClick={copySignupLink} className="post-button">
            Copy Signup Link
          </button>

          {alertMessage && <p className="post-alert-message">{alertMessage}</p>}
        </div>
      </div>
    </PageLayout>
  );
};

export default Post;
