/* eslint-disable prettier/prettier */
//src\pages\home-page.js
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { Auth0Features } from "../components/auth0-features";
import { HeroBanner } from "../components/hero-banner";
import { PageLayout } from "../components/page-layout";
import { useQueryParamsToLocalStorage } from "../hooks/useQueryParams.js";

export const HomePage = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useQueryParamsToLocalStorage();

  useEffect(() => {
    // Redirect to /dashboard if the user is already logged in
    if (isAuthenticated) {
      navigate("/dashboard");
      return; // Stop further execution if redirecting
    }

    const urlParams = new URLSearchParams(window.location.search);
    const signup = urlParams.get("signup");

    if (signup) {
      loginWithRedirect({
        screen_hint: "signup",
      });
    }

    // Check for success message in query parameters for email verification
    const success = urlParams.get("success");
    const message = urlParams.get("message");

    if (success === "true" && message) {
      setAlertMessage(decodeURIComponent(message));
      setShowAlert(true); // Show the alert popup
    }
  }, [isAuthenticated, loginWithRedirect, navigate]);

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  return (
    <PageLayout>
      <HeroBanner />
      {showAlert && (
        <div className="alert-popup">
          <div className="alert-popup__content">
            <p>Your email is verified! You&apos;re ready to log in. Just click below to close this message, then select &quot;Log In&quot; at the top right.</p>
            <p style={{display: "none"}}>{alertMessage}</p>
            <button onClick={handleAlertClose}>Close</button>
          </div>
        </div>
      )}
      <Auth0Features />
    </PageLayout>
  );
};
