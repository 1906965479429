// src/hooks/useRetoolPostMessage.js

import { useEffect } from "react";

/**
 * Custom hook to handle postMessage events from Retool's iframe.
 *
 * @param {string} expectedOrigin - The origin to validate incoming messages.
 * @param {function} [callback] - Optional callback to execute with the message data.
 */
const useRetoolPostMessage = (expectedOrigin, callback) => {
  useEffect(() => {
    /**
     * Handler for message events.
     *
     * @param {MessageEvent} event - The message event.
     */
    const handleMessage = (event) => {
      // Validate the message origin
      if (event.origin !== expectedOrigin) {
        console.warn(`Ignored message from unexpected origin: ${event.origin}`);
        return;
      }

      // Extract title and body from the message data
      const { title, body } = event.data;

      if (typeof title === "string") {
        if (callback && typeof callback === "function") {
          callback({ title, body });
        } else {
          // Default behavior: Display an alert (you can customize this)
          if (body) {
            alert(`${title}\n\n${body}`);
          } else {
            alert(title);
          }
        }
      } else {
        console.warn("Received message without a valid title:", event.data);
      }
    };

    // Add the event listener
    window.addEventListener("message", handleMessage);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [expectedOrigin, callback]);
};

export default useRetoolPostMessage;
