/* eslint-disable prettier/prettier */
// src/pages/brand-rules.js

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Retool from "react-retool";
import useIframeRedirect from "../hooks/useIframeRedirect"; // Keep the redirect hook
import useRetoolPostMessage from "../hooks/useRetoolPostMessage"; // Import the new hook

const BrandRulesPage = () => {
  useDocumentTitle("Brand Rules");

  const { user } = useAuth0();

  // Initialize the iframe redirect hook with the expected origin
  useIframeRedirect("https://tryrepbot.retool.com");

  // Initialize the new Retool postMessage hook
  useRetoolPostMessage("https://tryrepbot.retool.com");

  // Data to pass to Retool, customize as needed
  const retoolData = {
    userEmail: user.email,
    // Add other necessary data here
  };

  return (
    <PageLayout>
      <Retool
        url="https://tryrepbot.retool.com/p/response-tone-onboarding" // Updated URL for your Retool app
        width="100%"
        height="1000px"
        data={retoolData} // Pass all necessary data to Retool
      />
    </PageLayout>
  );
};

export default BrandRulesPage;
