/* eslint-disable prettier/prettier */
// src/pages/dashboard-page.js
import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Retool from "react-retool";
import Modal from "../components/modal";
import GoogleSSO from "../components/GoogleSSO";
import useIframeModal from "../hooks/useIframeModal"; // Only removing useAccountInfo
import useIframeRedirect from "../hooks/useIframeRedirect"; // Import the redirect hook


const DashboardPage = () => {
  useDocumentTitle("Dashboard");

  const { user } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Initialize the iframe modal hook with the expected origin and callback
  useIframeModal("https://tryrepbot.retool.com", () => setIsModalOpen(true));

    // Initialize the iframe redirect hook with the expected origin
    useIframeRedirect("https://tryrepbot.retool.com");


  // Pull values from localStorage
  let credit_Amount = localStorage.getItem("utm_type");
  let utm_type = localStorage.getItem("utm_type");

  const cid = localStorage.getItem("cid") || null;

  // Determine numeric value for credit_Amount based on the provided conditions
  if (credit_Amount === "review-response-tool") {
    credit_Amount = 10;
  } else if (credit_Amount === "trial") {
    credit_Amount = 30;
  } else if (credit_Amount && credit_Amount.startsWith("paid-")) {
    // Extract the numeric value after "paid-" (e.g., "paid-50" -> 50)
    credit_Amount = parseInt(credit_Amount.split("-")[1], 10) || 10;
  } else {
    // Default to 10 if credit_Amount is null or doesn't match other cases
    credit_Amount = 10;
  }

  // Data to pass to Retool, structured as a nested object
  const retoolData = {
    accountInfo: {
      userEmail: user.email,
      credit_Amount,
      utm_type,
      cid,
    }
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close modal
  };

  return (
    <PageLayout>
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <GoogleSSO />
        </Modal>
      )}

      <Retool
        url="https://tryrepbot.retool.com/p/dashboard" 
        width="100%"
        height="800px"
        data={retoolData}
      />
    </PageLayout>
  );
};

export default DashboardPage;