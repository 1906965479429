/* eslint-disable prettier/prettier */
// src/pages/response-tone-onboarding.js

import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Retool from "react-retool";
import Modal from "../components/modal"; // Import the Modal component
import GoogleSSO from "../components/GoogleSSO"; // Import Google SSO Component
import useIframeModal from "../hooks/useIframeModal"; // Import the modal hook
import useIframeRedirect from "../hooks/useIframeRedirect"; // Import the redirect hook

const ResponseToneOnboardingPage = () => {
  useDocumentTitle("Response Tone Onboarding");

  const { user } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [retoolData, setRetoolData] = useState({}); // State to hold retool data

  // Initialize the iframe modal hook with the expected origin and callback
  useIframeModal("https://tryrepbot.retool.com", () => setIsModalOpen(true));

  // Initialize the iframe redirect hook with the expected origin
  useIframeRedirect("https://tryrepbot.retool.com");

  useEffect(() => {
    // Retrieve cid from localStorage and set retoolData
    const cid = localStorage.getItem("cid");

    setRetoolData({
      userEmail: user.email,
      cid: cid || null, // If cid exists, add it; if not, add null or skip it
    });
  }, []); // Empty dependency array to run only on mount

  const closeModal = () => {
    setIsModalOpen(false); // Close modal
  };

  return (
    <PageLayout>
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <GoogleSSO />
        </Modal>
      )}

      <Retool
        url="https://tryrepbot.retool.com/p/response-tone-onboarding" // URL for your Retool app
        width="100%"
        height="1000px"
        data={retoolData} // Pass all necessary data to Retool
      />
    </PageLayout>
  );
};

export default ResponseToneOnboardingPage;
