/* eslint-disable prettier/prettier */
// src\pages\callback-page.js  
import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavBar } from "../components/navigation/desktop/nav-bar";
import { MobileNavBar } from "../components/navigation/mobile/mobile-nav-bar";
import { PageLayout } from "../components/page-layout";

export const CallbackPage = () => {
  const { error, isAuthenticated, isLoading, handleRedirectCallback } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuth = async () => {
      try {
        const { appState } = await handleRedirectCallback();

        // Check if this was a sign-up or login and redirect accordingly
        if (appState?.loginType === "signup") {
          window.location.href = appState.returnTo;  // Redirect to the custom onboarding URL
        } else {
          navigate(appState?.returnTo || "/dashboard");  
        }
      } catch (err) {
        console.error("Error handling redirect callback:", err);
        // Handle errors accordingly
      }
    };

    handleAuth();
  }, [handleRedirectCallback, navigate]);

  // Check if the error is specifically for unverified email
  const urlParams = new URLSearchParams(window.location.search);
  const errorDescription = urlParams.get("error_description");

  if (errorDescription && errorDescription.includes("Please check your email to verify your account")) {
    return (
      <PageLayout>
        <div className="content-layout">
          <h1 id="page-title" className="content__title">Email Verification Required</h1>
          <div className="content__body">
            <p id="page-description">
              Thank you for registering! Please verify your email to proceed.
              If you haven&apos;t received the email, check your spam folder or contact support at <a href="mailto:support@repbot.ai">support@repbot.ai</a>.
            </p>
          </div>
        </div>
      </PageLayout>
    );
  }

  if (isLoading) {
    return (
      <PageLayout>
        <div className="content-layout">
          <h1 id="page-title" className="content__title">Loading...</h1>
          <div className="content__body">
            <p id="page-description">Please wait while we complete the authentication process.</p>
          </div>
        </div>
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <div className="content-layout">
          <h1 id="page-title" className="content__title">Authentication Error</h1>
          <div className="content__body">
            <p id="page-description">
              <span>{error.message}</span>
            </p>
          </div>
        </div>
      </PageLayout>
    );
  }

  return (
    <div className="page-layout">
      <NavBar />
      <MobileNavBar />
      <div className="page-layout__content">
        {isAuthenticated ? (
          <p>Successfully authenticated. Redirecting...</p>
        ) : (
          <p>Redirecting to login...</p>
        )}
      </div>
    </div>
  );
};
