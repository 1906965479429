/* eslint-disable prettier/prettier */
// src/pages/reviews.js

import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Retool from "react-retool";
import Modal from "../components/modal"; // Import the Modal component
import GoogleSSO from "../components/GoogleSSO"; // Import Google SSO Component
import useIframeModal from "../hooks/useIframeModal"; // Import the modal hook
import useIframeRedirect from "../hooks/useIframeRedirect"; // Import the redirect hook

const ReviewsPage = () => {
  useDocumentTitle("Reviews");

  const { user } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  // Initialize the iframe modal hook with the expected origin and callback
  useIframeModal("https://tryrepbot.retool.com", () => setIsModalOpen(true));

  // Initialize the iframe redirect hook with the expected origin
  useIframeRedirect("https://tryrepbot.retool.com");

  // Data to pass to Retool, customize as needed
  const retoolData = {
    userEmail: user.email,
    // Add other necessary data here
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close modal
  };

  return (
    <PageLayout>
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <GoogleSSO />
        </Modal>
      )}

      <Retool
        url="https://tryrepbot.retool.com/p/reviews" // URL for your Retool app
        width="100%"
        height="1000px"
        data={retoolData} // Pass all necessary data to Retool
      />
    </PageLayout>
  );
};

export default ReviewsPage;
