/* eslint-disable prettier/prettier */
// src/app.js

import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Routes, Route } from "react-router-dom";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { CallbackPage } from "./pages/callback-page";
import GMBIntegrationPage from "./pages/gmb-integration";
import ResponseToneOnboardingPage from "./pages/response-tone-onboarding";
import ReviewsPage from "./pages/reviews";
import BrandRulesPage from "./pages/brand-rules"; // Import the BrandRulesPage
import Post from "./pages/post";

import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePage } from "./pages/profile-page";
import ProtectedPage from "./pages/protected-page";
import { PublicPage } from "./pages/public-page";
import SettingsPage from "./pages/settings-page";
import DashboardPage from "./pages/dashboard-page";

export const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <div className="app-layout">
      <div className="main-content">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/gmb-integration"
            element={<AuthenticationGuard component={GMBIntegrationPage} />}
          />
          <Route
            path="/response-tone-onboarding"
            element={<AuthenticationGuard component={ResponseToneOnboardingPage} />}
          />
          <Route
            path="/reviews"
            element={<AuthenticationGuard component={ReviewsPage} />}
          />
          <Route
            path="/brand-rules"
            element={<AuthenticationGuard component={BrandRulesPage} />} // New Route Added
          />
          <Route path="/post" element={<Post />} />
          <Route
            path="/profile"
            element={<AuthenticationGuard component={ProfilePage} />}
          />
          <Route path="/public" element={<PublicPage />} />
          <Route
            path="/protected"
            element={<AuthenticationGuard component={ProtectedPage} />}
          />
          <Route
            path="/dashboard"
            element={<AuthenticationGuard component={DashboardPage} />}
          />
          <Route
            path="/settings"
            element={<AuthenticationGuard component={SettingsPage} />}
          />
          <Route path="/callback" element={<CallbackPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </div>
  );
};
